/* TL.TimeNav
================================================== */

.tl-timenav {
	//height:200px;
	width:100%;
	background-color:@ui-background-color;
	position:relative;
	overflow:hidden;
	//border-top: 2px solid @ui-background-color;
	border-top: 1px solid darken(@ui-background-color,5);
	//border-top: 1px solid #e3e3e3;
	//box-shadow: inset 10px 10px 5px 0px rgba(0,0,0,0.75);
	//.box-shadow(inset -7px 0px 7px rgba(0,0,0,.30));

	.tl-timenav-line {
		position: absolute;
		top: 0;
		left: 50%;
		width: 1px;
		height: 100%;
		background-color: darken(@ui-background-color, 10);//@color-theme;//darken(@ui-background-color, 10);
		z-index: 2;
		display:none;
		//box-shadow: 1px 1px 7px rgba(0,0,0,0.3);
		&:before, &:after {
			font-family: 'tl-icons';
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			
			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			
			color:@color-theme;
			font-size:32px;
			line-height:32px;
			position: absolute;
			left:-14px;
		}
		&:before {
			//content: "\e647";
			top: -10px;
		}
		&:after {
			content: "\e648";
			bottom:24px;
		}
	}
	.tl-timenav-slider {
		position:absolute;
		height:100%;
		width:100%;
		top:0;
		
		&.tl-timenav-slider-animate {
			
			.property-animation(all, @animation-duration, @animation-ease);
			.animation-timing-cubic-bezier();
		}
		.tl-timenav-slider-background {
			position:absolute;
			height:100%;
			width:100%;
			cursor:move;
			z-index:6;
		}
		.tl-timenav-container-mask {
			position:absolute;
			height:100%;
			top:0;
			.tl-timenav-container {
				position:absolute;
				height:100%;

			
				.tl-timenav-item-container {
					//margin-top:5px;
					position:absolute;
					height:100%;
				}
			
			}
		
		}
	}
	
	
}

/* Skinny
================================================== */
.tl-skinny {
	.tl-timenav {

	}
}

/* Mobile, iPhone
================================================== */
.tl-mobile {
	.tl-timenav {

	}
}

/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny {
	.tl-timenav {

	}
}


//custom
.loader_background{
	background-color: #000;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 102;
}

.loader_text{
	position: absolute;
	top: 70%;
	left: 50%;
	transform: translate(-50%, -70%);
	z-index: 104;
	color: #fff;
	font-size: 30px;
	font-weight: bold;
}
