/* TL.TimeMarker
================================================== */

.tl-timegroup {
    //height:100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background-color: @ui-background-color;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    .tl-timegroup-message {
        //z-index:6;
        // color:@color-main;
        box-sizing: border-box;
        padding-left: 64px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        height: 100%;
        background-color: @ui-background-color;
        z-index: 10;
        white-space: nowrap;
    }

    &.tl-timegroup-alternate {
        background-color: lighten(@ui-background-color, 3);
        .tl-timegroup-message {
            //background-color:lighten(@ui-background-color,3);
        }
    }
    &.tl-timegroup-hidden {
        display: none;
    }

    /* Animate Left Width and Top
	================================================== */
    .property-animation(left, @animation-duration, @animation-ease);
    .property-animation(top, @animation-duration-fast, @animation-ease);
    .property-animation(height, @animation-duration-fast, @animation-ease);
    .property-animation(width, @animation-duration, @animation-ease);
    .animation-timing-cubic-bezier();
}

/* Skinny
================================================== */
.tl-skinny {
    .tl-timegroup {
    }
}

/* Mobile, iPhone
================================================== */
.tl-mobile {
    .tl-timegroup {
    }
}

/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny {
    .tl-timegroup {
    }
}
