/* TL.TimeFilter
================================================== */

.tl-filterbar {
    position: absolute;
    width: 100%;
    z-index: 9999;
    background-color: #4e4e4e;
    color: #fff;
    font-family: "PT Sans Narrow", sans-serif;
    margin-top: -86px;
    @media (min-width: 1024px) {
        margin-top: -45px;
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        padding: 6px 10px;
    }
    .flexbox {
        display: flex;
    }
    .flexWrap {
        flex-wrap: wrap;
    }
    .flex1 {
        flex: 1;
    }
    .field-group {
        display: flex;
        flex: 1;
    }
    .field {
        display: flex;
        align-items: center;
        margin-right: 10px;
        label {
            margin-right: 6px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .field-keyword {
        min-width: 260px;
        width: 100%;
    }
    input,
    select {
        color: #fff;
        font-family: "PT Sans Narrow", sans-serif;
        font-size: 16px;
        padding: 4px 6px;
        border: 1px solid rgba(#fff, 0.4);
        background-color: rgba(#fff, 0.1);
        border-radius: 4px;
        color-scheme: dark;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: rgba(#fff, 0.4);
        }
        &[type="month"] {
            width: 134px;
        }
        option {
            background-color: #323232;
        }
    }
    .btn {
        font-family: "PT Sans Narrow", sans-serif;
        font-size: 17px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        background-color: rgba(#fff, 0.3);
        border-radius: 4px;
        border: none;
        cursor: pointer;
        margin: 0 4px;
        display: flex;
        align-items: center;
        svg + span {
            margin-left: 8px;
            display: none;
            @media (min-width: 1024px) {
                display: inline-flex;
            }
        }
    }
    .btn:focus {
        outline: none;
        background-color: rgba(#fff, 0.4);
    }
    .btn-group {
        display: flex;
    }
}
