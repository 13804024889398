/*!
	Timeline JS 3

	Designed and built by Zach Wise for the Northwestern University Knight Lab

	This Source Code Form is subject to the terms of the Mozilla Public
	License, v. 2.0. If a copy of the MPL was not distributed with this
	file, You can obtain one at https://mozilla.org/MPL/2.0/.

*/

/* Includes
================================================== */

@import "core/Mixins.less"; // Less shortcuts and functions
@import "Typography.less";

// Icons
@import "icons/Icons.less";

//	TL
@import "core/TL.less";

//	Components
@import "ui/TL.MenuBar.less";
@import "ui/TL.MenuBar.Button.less";
@import "ui/TL.Message.less";

@import "timenav/TL.TimeNav.less";
@import "timenav/TL.TimeFilter.less";
@import "timenav/TL.TimeMarker.less";
@import "timenav/TL.TimeEra.less";
@import "timenav/TL.TimeGroup.less";
@import "timenav/TL.TimeAxis.less";

@import "animation/TL.Animate.less";

@import "slider/TL.Slide.less";
@import "slider/TL.SlideNav.less";
@import "slider/TL.StorySlider.less";

@import "media/TL.Media.less";
@import "media/types/TL.Media.Text.less";
@import "media/types/TL.Media.Image.less";
@import "media/types/TL.Media.Twitter.less";
@import "media/types/TL.Media.Blockquote.less";
@import "media/types/TL.Media.Flickr.less";
@import "media/types/TL.Media.Instagram.less";
@import "media/types/TL.Media.Profile.less";
@import "media/types/TL.Media.YouTube.less";
@import "media/types/TL.Media.IFrame.less";
@import "media/types/TL.Media.Wikipedia.less";
@import "media/types/TL.Media.Website.less";

/* Timeline
================================================== */

.tl-timeline {
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: normal;
    overflow: hidden;
    position: relative;
    .user-select(none);
    background-color: @color-background;
    color: @color-text;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;

    &.tl-timeline-embed {
        box-sizing: border-box;
        border-top: 1px solid darken(@ui-background-color-darker, 10);
        border-bottom: 1px solid darken(@ui-background-color-darker, 10);
        border-radius: 0;
    }

    &.tl-timeline-full-embed {
        box-sizing: border-box;
        border: 1px solid darken(@ui-background-color-darker, 10);
        border-radius: 8px;
    }

    .tl-attribution {
        cursor: pointer;
        z-index: 9;
        position: absolute;
        bottom: 2px;
        left: 0px;
        font-size: 10px;
        line-height: 10px;
        font-family: @font-sanserif !important;
        background-color: fadeout(@color-background, 15%);
        padding: 3px;

        a {
            color: @brand-color;
            &:hover,
            &:focus-visible {
                color: @color-dark;
                text-decoration: none;
                .tl-knightlab-logo {
                    background-color: #c34528;
                }
            }
        }

        .tl-knightlab-logo {
            display: inline-block;
            vertical-align: middle;
            height: 8px;
            width: 8px;
            margin-right: 3px;
            background-color: #c34528;
            background-color: @brand-color;
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
    }
}

/* Portrait
================================================== */
.tl-layout-portrait {
    .tl-storyslider {
        //padding-top:10px;
        .box-shadow(0px -3px 6px rgba(0,0,0,0.2));
    }
}

.tl-rtl {
    .tl-text-content,
    .tl-headline,
    .tl-media-blockquote,
    .tl-headline-date,
    .tl-timeline blockquote p,
    .tl-media-website,
    .tl-media-wikipedia,
    .tl-media .tl-media-blockquote blockquote,
    .blockquote,
    blockquote p,
    .tl-text-content p {
        text-align: right;
        direction: rtl;
    }

    .tl-slide-media-only {
        .tl-headline,
        .tl-headline-date {
            text-align: center;
        }
    }

    .tl-timemarker-text {
        margin-right: 35px;
    }

    .tl-timemarker-content-container
        .tl-timemarker-content
        .tl-timemarker-media-container {
        float: right;
    }

    .tl-caption {
        text-align: right;
    }

    .tl-credit {
        text-align: left;
    }
}
